import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../share.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  angForm: FormGroup;
  constructor(private shareservice: ShareService, private fb: FormBuilder) {
    this.createForm();
   }
  createForm() {
    this.angForm = this.fb.group({
      title: ['', Validators.required] ,
      description: ['' ],
      talent: ['']
   });
  }
  addShare(title, description, talent) {
     const dataObj = {
      title: title,
      description: description,
      talent: talent
    };
    this.shareservice.addShare(dataObj);
  }
  ngOnInit() {
  }
}