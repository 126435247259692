import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { FsService } from '../fs.service';

@Component({
  selector: 'app-surveyresults',
  templateUrl: './surveyresults.component.html',
  styleUrls: ['./surveyresults.component.scss']
})


export class SurveyresultsComponent implements OnInit {
 
  displayedColumns = ['name', 'rateTrial', 'creates', 'issues', 'expectations', 'motivates', 'programs', 'otherApp', 'interest', 'comments', 'myDate' ];
  dataSource = new BoardDataSource(this.fs);
  

  constructor(private fs: FsService) {
  }


  ngOnInit() {


  }

  ngAfterViewInit() {
  }
  

}

export class BoardDataSource extends DataSource<any> {

  constructor(private fs: FsService) {
    super()
  }

  connect() {
    return this.fs.getSurveys();
  }

  disconnect() {

  }

  

  
}