import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import {MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSortModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatStepperModule,
  MatSelectModule,
   } from "@angular/material";
   import { AngularFireModule } from '@angular/fire';
   import { AngularFirestoreModule } from '@angular/fire/firestore';
   import { AngularFireStorageModule } from '@angular/fire/storage';
   import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AuthService } from './auth.service';
import { ChartsModule } from 'ng2-charts';

import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Router, Routes } from '@angular/router'; 
import { User } from  'firebase';

import { RouterModule,  } from '@angular/router';
import { CreateComponent } from './components/create/create.component';
import { IndexComponent } from './components/index/index.component';
import {ShareService} from './share.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardDetailComponent } from './dashboard-detail/dashboard-detail.component';
import { BoardsCreateComponent } from './boards-create/boards-create.component';
import { BoardsCreateStepperComponent } from './boards-create-stepper/boards-create-stepper.component';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BoardsEditComponent } from './boards-edit/boards-edit.component';
import {AuthGuard} from './core/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AdIdeasComponent } from './ad-ideas/ad-ideas.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { SurveyComponent } from './survey/survey.component';
import { SurveyresultsComponent } from './surveyresults/surveyresults.component';
import { SurveydetailComponent } from './surveydetail/surveydetail.component';
import { HomeComponent } from './home/home.component';
import { LineChartDemoComponent } from './line-chart-demo/line-chart-demo.component';
import { ThanksComponent } from './thanks/thanks.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { DigitalcoachComponent } from './digitalcoach/digitalcoach.component';
import { SchedulerComponent } from './scheduler/scheduler.component';

const appRoutes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
  data: { title: 'Dashboard List' }},
  { path: 'create', component: CreateComponent, canActivate: [AuthGuard],}, 
{ path: 'index', component: IndexComponent},
{
  path: 'dashboard-details/:id',
  component: DashboardDetailComponent,canActivate: [AuthGuard],
  data: { title: 'Dashboard Details'}
},

{
  path: 'surveydetail/:id',
  component: SurveydetailComponent,canActivate: [AuthGuard],
  data: { title: 'Survey Detail'}
},

{
  path: 'boards-create',
  component: BoardsCreateComponent, canActivate: [AuthGuard],
  data: { title: 'Create Boards' }
},
{
  path: 'digitalcoach',
  component: DigitalcoachComponent, canActivate: [AuthGuard],
  data: { title: 'Digital Coach' }
},
{
  path: 'thanks',
  component: ThanksComponent,
  data: { title: 'Thanks' }
},
{
  path: 'welcome',
  component: WelcomeComponent,
  data: { title: 'Welcome' }
},
{
  path: 'boards-create-stepper',
  component: BoardsCreateStepperComponent, canActivate: [AuthGuard],
  data: { title: 'Create Stepper Boards' }
},

{
  path: 'surveyresults',
  component: SurveyresultsComponent, canActivate: [AuthGuard],
  data: { title: 'Survey Results' }
},

{
  path: 'boards-edit/:id',
  component: BoardsEditComponent,canActivate: [AuthGuard],
  data: { title: 'Edit Boards' }
},
{
  path: 'line-chart-demo',
  component: LineChartDemoComponent,canActivate: [AuthGuard],
  data: { title: 'Line Chart Demo' }
},
/* { path: 'user-profile',
  component: UserProfileComponent,
data: {title: 'User Profile'}
}, */

 { path: 'home',
  component: HomeComponent,
data: {title: 'Home'}
},
{ path: 'dashboard-card',
  component: DashboardCardComponent,canActivate: [AuthGuard],
data: {title: 'Dashboard Card'}
},
{ path: 'ad-ideas',
  component: AdIdeasComponent,canActivate: [AuthGuard],
data: {title: 'Ad Ideas'}
},
{
  path: 'survey',
  component: SurveyComponent,
  data: { title: 'Survey' }
},
{ path: '',
  redirectTo: '/welcome',
  pathMatch: 'full'
}


];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    IndexComponent,
    CreateComponent,
    DashboardDetailComponent,
    BoardsCreateComponent,
    BoardsEditComponent,
    BoardsCreateStepperComponent,
    UserProfileComponent,
    AdIdeasComponent,
    DashboardCardComponent,
    SurveyComponent,
    SurveyresultsComponent,
    SurveydetailComponent,
    HomeComponent,
    LineChartDemoComponent,
    ThanksComponent,
    WelcomeComponent,
    DigitalcoachComponent,
    SchedulerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    OAuthModule.forRoot(),

    
      RouterModule.forRoot(
        appRoutes,
      ),
      
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireDatabaseModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase,'contentportal'),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    RouterModule.forRoot(appRoutes),
    AngularFireAuthModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatGridListModule,
    ChartsModule,
  ],
  providers: [AuthService,
              ShareService,
              AuthGuard,
            ],
  bootstrap: [AppComponent],
  entryComponents: [SchedulerComponent]

})
export class AppModule { }
