import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FsService } from '../fs.service';
import {MatStepperModule} from '@angular/material'
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import firestore from 'firebase/firestore'
import * as firebase from 'firebase';



@Component({
  selector: 'app-boards-create-stepper',
  templateUrl: './boards-create-stepper.component.html',
  styleUrls: ['./boards-create-stepper.component.scss']
})
export class BoardsCreateStepperComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  title:string='';
  description:string='';
  author:string='';
  talent:string = '';
  seriestotal:string = '';
  seriestarget:string = '';
  seriesequipment:string= '';
  awslocation:string= '';
  startdate:string = '';
  myDate: string = '';
  

  constructor(private _formBuilder: FormBuilder, private fs: FsService, private router: Router) {}

  ngOnInit() {
    
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators],
      title: ['', Validators.required],
      description: ['',Validators.required],
      talent: ['', Validators],
      secondCtrl: ['', Validators],
      awslocation: ['', Validators],
      seriestarget: ['', Validators],
      startdate: ['',Validators],
      myDate: [ firebase.firestore.FieldValue.serverTimestamp(),'' ],

    


      
    });
    this.secondFormGroup = this._formBuilder.group({
      
      
    });
    

}
onFormSubmit(form:NgForm) {
  this.fs.postBoards(form)
    .subscribe(res => {
        let id = res['key'];
      }, (err) => {
        console.log(err);
      });
  
    }
  }

