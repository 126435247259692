import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FsService } from '../fs.service';
import {MatStepperModule, MatSlider,MatSelectModule} from '@angular/material'
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import firestore from 'firebase/firestore'
import * as firebase from 'firebase';

export interface Options {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})



export class SurveyComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup:FormGroup;
  name:string='';
  rateTrial:number;
  experienceTrial:string='';
  improveTrial:string='';
  findTrial:string = '';
  comments:string ='';
  myDate: string = '';
otherApp: string= '';
creates: string = '';
interest: string ='';




expectations: Options[] = [ {value: 'expectation-0', viewValue: 'Weight loss'},
                                {value: 'expectation-1', viewValue: 'Improved A1c/Blood sugar'},
                                {value: 'expectation-2', viewValue: 'Diabetes guidance'},
                                {value: 'expectation-3', viewValue: 'Exercise guidance'},
                                {value: 'expectation-4', viewValue: 'Community of peers'},
                            
];

issues: Options[] = [           {value: 'issue-0', viewValue: 'I had a technical problem'},
                                {value: 'issue-1', viewValue: 'I am not sure what to do'},
                                {value: 'issue-2', viewValue: 'I do not have the equipment'},
                                {value: 'issue-3', viewValue: 'I did not have an issue'},
                                {value: 'issue-4', viewValue: 'The app is too expensive'},
                                
                              {value: 'issue-5', viewValue: 'I do not have enough time'},

                                
];

motivates: Options[] = [ {value: 'motivate-0', viewValue: 'If I had a coach'},
                                {value: 'motivate-1', viewValue: 'If it was more user-friendly'},
                                {value: 'motivate-2', viewValue: 'If I liked the trainer(s) more'},
                                {value: 'motivate-3', viewValue: 'If the exercise was less intimidating'},
                                {value: 'motivate-4', viewValue: 'If the pictures were less intimidating'},
                                {value: 'motivate-5', viewValue: 'If the quality of workouts was better'},
                                {value: 'motivate-6', viewValue: 'If I saw my blood sugar improve during a class'},
                                {value: 'motivate-7', viewValue: 'I’m not interested'},


];

programs: Options[] = [ {value: 'program-0', viewValue: 'I prefer structured programs'},
                                {value: 'program-1', viewValue: 'I like to workout on my own'},
                                
];


  
  constructor(private _formBuilder: FormBuilder, private fs: FsService, private router: Router) {}

  ngOnInit() {
    
    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      rateTrial:['',Validators],
     comments: [''],
      myDate: [ firebase.firestore.FieldValue.serverTimestamp(),'' ],
      otherApp:[''],
   creates:[''],
   interest:[''],

   expectations:[],
   motivates:[],
   issues:[],
   programs: [],
   contact:[''],




    


      
    });
    this.secondFormGroup = this._formBuilder.group({
      
      
    });

    this.thirdFormGroup = this._formBuilder.group({
      
      
    });
    

}
onFormSubmit(form:NgForm) {
  this.fs.postSurveys(form)
    .subscribe(res => {
        let id = res['key'];
      }, (err) => {
        console.log(err);
      });
  
    }

  }

