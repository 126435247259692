import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore';
import { AuthService } from './core/auth.service';


const settings = {timestampsInSnapshots: true};

// <!-- firestore -->
const config = {
  apiKey: "AIzaSyCDrMFokG5BCAOZu4XQZCRfhm20wgWPY8c",
    authDomain: "contentportal-97719.firebaseapp.com",
    databaseURL: "https://contentportal-97719.firebaseio.com",
    projectId: "contentportal-97719",
    storageBucket: "contentportal-97719.appspot.com",
    messagingSenderId: "585346998534"
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  

  constructor(public auth: AuthService, public router: Router) {} 

  
  
  ngOnInit() {
    firebase.initializeApp(config);
    firebase.firestore().settings(settings);
  }
}



