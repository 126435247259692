import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material";
import { SchedulerComponent } from '../scheduler/scheduler.component';


@Component({
  selector: 'app-digitalcoach',
  templateUrl: './digitalcoach.component.html',
  styleUrls: ['./digitalcoach.component.scss']
})
export class DigitalcoachComponent {

  constructor(private dialog: MatDialog) {}

  openDialog() {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;

      this.dialog.open(SchedulerComponent, dialogConfig);
  }
}
