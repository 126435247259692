import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FsService } from '../fs.service';
import {MatGridListModule} from '@angular/material'

@Component({
  selector: 'app-surveydetail',
  templateUrl: './surveydetail.component.html',
  styleUrls: ['./surveydetail.component.scss']
})
export class SurveydetailComponent implements OnInit {
  survey = {};

  constructor(private route: ActivatedRoute, private router: Router, private fs: FsService) { }
  getSurveyDetails(id) {
    this.fs.getSurvey(id)
      .subscribe(data => {
        console.log(data);
        this.survey = data;
      });
  }
  ngOnInit() {
    this.getSurveyDetails(this.route.snapshot.params['id']);

  }
  deleteSurvey(id) {
    this.fs.deleteSurveys(id)
      .subscribe(res => {
          this.router.navigate(['/surveyresults']);
        }, (err) => {
          console.log(err);
        }
      );
  }
}
