import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth.service';
import {MatGridListModule} from '@angular/material'
import * as firebase from 'firebase';
import firestore from 'firebase/firestore';
import { FsService } from '../fs.service';
import { Observable } from 'rxjs/Observable';



@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  boards = {};
  constructor(private route: ActivatedRoute, private router: Router, private fs: FsService,) { }

  getBoards() {
    this.fs.getBoards()
      .subscribe(data => {
        console.log(data);
        this.boards = data;
      });
  }
  ngOnInit() {
    this.getBoards();

  }
  deleteBoard(id) {
    this.fs.deleteBoards(id)
      .subscribe(res => {
          this.router.navigate(['/dashboard']);
        }, (err) => {
          console.log(err);
        }
      );
  }
}
