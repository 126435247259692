import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

class Book {
  constructor(public title) { }
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {

  public books: AngularFireList<Book[]>;
  constructor(db: AngularFireDatabase) {
      this.books = db.list('/books');
  }
}
