import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

@Injectable({
  providedIn: 'root'
})
export class FsService {
  ref = firebase.firestore().collection('boards');
  refadIdeas =firebase.firestore().collection('adIdeas')
  refSurveys =firebase.firestore().collection('Surveys')

  constructor() { }

  getadIdeas (): Observable<any> {
    return new Observable((observer) => {
      this.refadIdeas.onSnapshot((querySnapshot) => {
        let adIdeas = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          adIdeas.push({
            key:doc.id,
            ad: data.ad,
            title: data.title,
          });
        });
        observer.next(adIdeas);
      })
    })

  }

  getIdea(id: string): Observable<any> {
    return new Observable((observer) => {
      this.refadIdeas.doc(id).get().then((doc) => {
        let data = doc.data();
        observer.next({
          key:doc.id,
          ad: data.ad,
          title: data.title,

        });
      });
    });
  }
  

  getBoards(): Observable<any> {
    return new Observable((observer) => {
      this.ref.onSnapshot((querySnapshot) => {
        let boards = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          boards.push({
            key: doc.id,
            title: data.title,
            description: data.description,
            startdate:data.startdate,

            author: data.author,
            talent: data.talent,
            seriestotal: data.seriestotal,
            seriestarget: data.seriestarget,
            seriesequipment:data.seriesequipment,
            awslocation: data.awslocation,
            myDate:data.myDate,

          });
        });
        observer.next(boards);
      });
    });
  }
  
  getBoard(id: string): Observable<any> {
    return new Observable((observer) => {
      this.ref.doc(id).get().then((doc) => {
        let data = doc.data();
        observer.next({
          key: doc.id,
          title: data.title,
          description: data.description,
          author: data.author,
          startdate:data.startdate,

          talent: data.talent,
          seriestotal: data.seriestotal,
          seriestarget: data.seriestarget,
            seriesequipment:data.seriesequipment,
            awslocation: data.awslocation,
            myDate: data.myDate,

        });
      });
    });
  }
  
  postBoards(data): Observable<any> {
    return new Observable((observer) => {
      this.ref.add(data).then((doc) => {
        observer.next({
          key: doc.id,
        });
      });
    });
  }
  
  updateBoards(id: string, data): Observable<any> {
    return new Observable((observer) => {
      this.ref.doc(id).set(data).then(() => {
        observer.next();
      });
    });
  }
  
  deleteBoards(id: string): Observable<{}> {
    return new Observable((observer) => {
      this.ref.doc(id).delete().then(() => {
        observer.next();
      });
    });
  }

  getSurveys(): Observable<any> {
    return new Observable((observer) => {
      this.refSurveys.onSnapshot((querySnapshot) => {
        let Surveys = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          Surveys.push({
            key: doc.id,
            name: data.name,
            rateTrial:data.rateTrial,
            experienceTrial: data.experienceTrial,
            improveTrial:data.improveTrial,
            findTrial: data.findTrial,
            whyTrial: data.whyTrial,
            comments:data.comments,
            interest:data.interest,
            contact:data.contact,
            creates:data.creates,
            expectations:data.expectations,
            issues:data.issues,
            otherApp:data.otherApp,
            programs:data.programs,
            motivates:data.motivates,

        
            myDate:data.myDate,

          });
        });
        observer.next(Surveys);
      });
    });
  }
  
  getSurvey(id: string): Observable<any> {
    return new Observable((observer) => {
      this.refSurveys.doc(id).get().then((doc) => {
        let data = doc.data();
        observer.next({
          key: doc.id,
          name: data.name,
          rateTrial:data.rateTrial,
          experienceTrial: data.experienceTrial,
          improveTrial:data.improveTrial,
          findTrial: data.findTrial,
          whyTrial: data.whyTrial,
          comments:data.comments,
          interest:data.interest,
          contact:data.contact,
          creates:data.creates,
          expectations:data.expectations,
          issues:data.issues,
          otherApp:data.otherApp,
          programs:data.programs,
          motivates:data.motivates,
      
          myDate:data.myDate,

        });
      });
    });
  }
  
  postSurveys(data): Observable<any> {
    return new Observable((observer) => {
      this.refSurveys.add(data).then((doc) => {
        observer.next({
          key: doc.id,
        });
      });
    });
  }
  
  updateSurveys(id: string, data): Observable<any> {
    return new Observable((observer) => {
      this.refSurveys.doc(id).set(data).then(() => {
        observer.next();
      });
    });
  }
  
  deleteSurveys(id: string): Observable<{}> {
    return new Observable((observer) => {
      this.refSurveys.doc(id).delete().then(() => {
        observer.next();
      });
    });
  }

  getRatings(): Observable<any> {
    return new Observable((observer) => {
      this.refSurveys.onSnapshot((querySnapshot) => {
        let ratings = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          ratings.push({
            key: doc.id,
            rateTrial:data.rateTrial,

          });
        });
        observer.next(ratings);
      });
    });
  }
}





