import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FsService } from '../fs.service';
import {MatGridListModule} from '@angular/material'
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';


@Component({
  selector: 'app-boards-create',
  templateUrl: './boards-create.component.html',
  styleUrls: ['./boards-create.component.scss']
  
})


export class BoardsCreateComponent implements OnInit {
  boardsForm: FormGroup;
  title:string='';
  description:string='';
  author:string='';
  talent:string = '';
  seriestotal:string = '';
  seriestarget:string = '';
  seriesequipment:string= '';
  awslocation:string= '';


  tiles = [
    {text: 'One', cols: 2, rows: 1, color: '#142A5C'},
    {text: 'Two', cols: 1, rows: 1, color: '#B7A0E8'},
    {text: 'Three', cols: 1, rows: 2, color: '#FF0000'},
    {text: 'Four', cols: 3, rows: 1, color: '#D9EDD9'},
  ];

  constructor(private router: Router, private fs: FsService, private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.boardsForm = this.formBuilder.group({
      'title' : [null, Validators.required],
      'description' : [null, Validators.required],
      'author' : [null, Validators.required],
      'talent' : [null, Validators.required],
      'seriestotal' : [null, Validators.required],
      'seriestarget' : [null, Validators.required],
      'seriesequipment' : [null, Validators.required],
      'awslocation' : [null],
    });
  }
  onFormSubmit(form:NgForm) {
    this.fs.postBoards(form)
      .subscribe(res => {
          let id = res['key'];
          this.router.navigate(['/dashboard-details', id]);
        }, (err) => {
          console.log(err);
        });
  }
  

}
