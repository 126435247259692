import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FsService } from '../fs.service';
import {MatGridListModule} from '@angular/material'

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.scss']
})
export class DashboardDetailComponent implements OnInit {
  board = {};

  constructor(private route: ActivatedRoute, private router: Router, private fs: FsService) { }
  getBoardDetails(id) {
    this.fs.getBoard(id)
      .subscribe(data => {
        console.log(data);
        this.board = data;
      });
  }
  ngOnInit() {
    this.getBoardDetails(this.route.snapshot.params['id']);

  }
  deleteBoard(id) {
    this.fs.deleteBoards(id)
      .subscribe(res => {
          this.router.navigate(['/dashboard']);
        }, (err) => {
          console.log(err);
        }
      );
  }
}
