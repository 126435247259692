import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable()
export class ShareService {

  private basePath = '/book';
  public items: any;
  public item: any;
  constructor(private db: AngularFireDatabase) { }

  addShare(data) {
  const obj = this.db.database.ref(this.basePath);
  obj.push(data);
  console.log('Success');
  }
}