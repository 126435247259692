import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { FsService } from '../fs.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
 
  displayedColumns = ['title', 'description', 'author', 'talent', 'seriestotal'];
  dataSource = new BoardDataSource(this.fs);
  

  constructor(private fs: FsService) {
  }


  ngOnInit() {


  }

  ngAfterViewInit() {
  }
  

}

export class BoardDataSource extends DataSource<any> {

  constructor(private fs: FsService) {
    super()
  }

  connect() {
    return this.fs.getBoards();
  }

  disconnect() {

  }

  

  
}