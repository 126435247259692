import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth.service';
import {MatGridListModule} from '@angular/material'

import { FsService } from '../fs.service';
import { Observable } from 'rxjs/Observable';


@Component({
  selector: 'app-ad-ideas',
  templateUrl: './ad-ideas.component.html',
  styleUrls: ['./ad-ideas.component.scss'],
  
})
export class AdIdeasComponent implements OnInit {

  ideas = {};

  constructor(private route: ActivatedRoute, private router: Router, private fs: FsService) { }
  getadIdeas() {
    this.fs.getadIdeas()
      .subscribe(data => {
        console.log(data);
        this.ideas = data;
      });
  }
  ngOnInit() {
    this.getadIdeas();

  }
  deleteBoard(id) {
    this.fs.deleteBoards(id)
      .subscribe(res => {
          this.router.navigate(['/dashboard']);
        }, (err) => {
          console.log(err);
        }
      );
  }
}
